import { useEffect } from "react"
import { useLocation } from "@reach/router"

const RedirectPage = () => {
	const location = useLocation()

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.location.href = `https://arc.net/privacy${location.hash}`
		}
	}, [location.hash])

	return null
}

export default RedirectPage
